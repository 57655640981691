<script setup>
import { getCurrentInstance, nextTick, onMounted, onUnmounted, reactive, ref, } from "vue";
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import dayjs from "dayjs";
// import storeTools from '../../tools/storeTools'
// import Photo from "@/components/CustomImage"
import detail from "./detail/index.vue";
import paychannel from "./paychannel/index.vue";
import stores from "./shop/index.vue";
import devices from "./device/index.vue";
import cashier from "./cashier/index.vue";
import shift from "./shift/index.vue";
import serviceCharge from "./serviceCharge/index.vue";
import resetPassword from "../../components/reSetPassword/index.vue";
import SmsConfigDialog from "@/components/SmsConfigDialog/index.vue";
import { CopyDocument, Operation, Search, Shop, Switch } from "@element-plus/icons-vue";

const { appContext } = getCurrentInstance();

let searchInfo = ref({
  created_at: '',
});

const handleCopy = (data) => {
  ElMessage({
    message: "复制成功",
    type: "success",
  });
  let tempcopy = document.createElement("input");
  tempcopy.value = data;
  document.body.appendChild(tempcopy);
  tempcopy.select();
  document.execCommand("Copy");
  document.body.removeChild(tempcopy);
};

const handleReset = () => {
  searchInfo.value.no = ""
  searchInfo.value.merchant_name = ""
  searchInfo.value.contact_person_name = ""
  searchInfo.value.business_type = ""
  searchInfo.value.created_at = ""
  searchInfo.value.contact_person_phone = ""
  searchInfo.value.merchant_id = "";
  queryAllMerchant();
};

const handleSearch = () => {
  hasSearchContent();
};

let merchantsType = reactive([
  {
    id: 0,
    label: "企业",
  },
  {
    id: 1,
    label: "个体工商户",
  },
  {
    id: 2,
    label: "小微商户",
  },
]);

// 表格
let isloading = ref(false);
let tableData = ref();
let pageinfo = reactive({
  perPage: 0, // 每页多少条
  total: 0, // 共计多少
  totalPages: 0, // 共计多少页
  currentPage: 0, // 当前页数
});

const merchant_detail = ref();

const handleMore = (row) => {
  merchant_detail.value.open(row);
};

const merchant_paychannel = ref();

const handleChannel = (row) => {
  nextTick(() => {
    merchant_paychannel.value.open(row);
  });
};

const merchant_store = ref();

const handleStore = (row) => {
  nextTick(() => {
    merchant_store.value.open(row);
  });
};

const merchant_cashier = ref();

const handleCashier = (row) => {
  nextTick(() => {
    merchant_cashier.value.open(row);
  });
};

const merchant_device = ref();

const handleDevice = (row) => {
  nextTick(() => {
    merchant_device.value.open(row);
  });
};

const merchant_recharge = ref();
const handleRecharge = (row) => {
  nextTick(() => {
    merchant_recharge.value.open(row);
  });
};

const pageChange = (page) => {
  hasSearchContent(page);
};

// 判断搜索框有没有数据
const hasSearchContent = (page) => {
  let info = searchInfo.value;
  if (Object.keys(info).length == 0) {
    queryAllMerchant({ page: page });
  } else {
    info.page = page;
    // queryAllMerchant(info)
    if (
      info.merchant_name ||
      info.no ||
      info.business_type ||
      info.contact_person_name
    ) {
      delete searchInfo.value.created_start;
      delete searchInfo.value.created_end;
      queryAllMerchant({
        business_type: info.business_type,
        contact_person_name: info.contact_person_name,
        merchant_name: info.merchant_name,
        no: info.no,
        page: info.page,
      });
    } else {
      queryAllMerchant(info);
    }
  }
};

// 查询商户
const queryAllMerchant = (data) => {
  isloading.value = true;
  if (data) {
    appContext.config.globalProperties.$api.merchants
      .query(data)
      .then((res) => {
        if (res.status == 200) {
          tableData.value = res.data.data;
          pageinfo.perPage = res.data.pagination.perPage;
          pageinfo.total = res.data.pagination.total;
          pageinfo.totalPages = res.data.pagination.totalPages;
          pageinfo.currentPage = res.data.pagination.currentPage;
          isloading.value = false;
        } else {
          ElMessage.error("数据加载失败,请稍后重试！");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    appContext.config.globalProperties.$api.merchants.query().then((res) => {
      if (res.status == 200) {
        tableData.value = res.data.data;
        pageinfo.perPage = res.data.pagination.perPage;
        pageinfo.total = res.data.pagination.total;
        pageinfo.totalPages = res.data.pagination.totalPages;
        pageinfo.currentPage = res.data.pagination.currentPage;
        isloading.value = false;
      } else {
        ElMessage.error("数据加载失败,请稍后重试！");
      }
    });
  }
};

onUnmounted(() => {
  console.log("unmounted merchant");
});

const optionsMerchant = ref([])
const getOptionsMerchant = async (data) => {
  await appContext.config.globalProperties.$api.dict.getMerchantDict(data).then(res => {
    if (res.status == 200) {
      optionsMerchant.value = res.data.data
    }
  })
}

onMounted(() => {
  console.log("on mounted merchant");
  getOptionsMerchant()
  // queryAllMerchant({created_start:GetCurrentMonthTime(),created_end:GetCurrentTime()})
  queryAllMerchant();
  queryAgents();
});

const handleDel = (row) => {
  ElMessageBox.alert("确定删除该商户", "温馨提示", {
    confirmButtonText: "确定",
    type: "warning",
    callback: (action) => {
      if (action == "confirm") {
        appContext.config.globalProperties.$api.merchants
          .delete(row.id)
          .then((r) => {
            if (r.status == 200) {
              ElNotification({
                title: "Success",
                message: "删除成功",
                type: "success",
              });
              // queryAllMerchant({created_start:GetCurrentMonthTime(),created_end:GetCurrentTime()})
              queryAllMerchant();
            } else {
              console.log(r);
            }
          });
      }
    },
  });
};
const selectedArrData = ref([]);
const selectDate = ref([]);
const handleSelectionChange = (data) => {
  selectDate.value = data.map((item) => item.id);
  selectedArrData.value = data;

};

// 设置选中行的样式
const isRed = ({ row }) => {
  const checkIdList = selectedArrData.value.map((item) => item.id);
  if (checkIdList.includes(row.id)) {
    return {
      backgroundColor: "#cde8f8"
    };
  }
};

const merchant_shift = ref();
const handleShift = () => {
  if (!selectDate.value.length) {
    ElMessage.warning("至少勾选一个商户");
    return;
  }
  nextTick(() => {
    merchant_shift.value.open(selectDate.value);
  });
};

const theAgentOption = ref([]);
const queryAgents = () => {
  appContext.config.globalProperties.$api
    .agentsList({ parent_id: "null", pageSize: 999 })
    .then((res) => {
      if (res.status == 200) {
        theAgentOption.value = res.data;
      }
    });
};

const dialogVisible = ref(false);
const handleUpdateOpen = (newValue) => {
  dialogVisible.value = newValue;
};
const merchantMes = ref({});
const handleReSetPassword = (row) => {
  dialogVisible.value = true;
  merchantMes.value = row;
};

const smsDialogVisible = ref(false);
const handleUpdateSmsConfig = (row) => {
  smsDialogVisible.value = true;
  merchantMes.value = row;
};
const handleUpdateSmsOpen = (newValue) => {
  smsDialogVisible.value = newValue;
};
</script>

<template>
  <div class="list-container" v-show="true">
    <el-card shadow="never">
      <div class="top-search">
        <el-form inline :model="searchInfo" label-width="100px" label-position="right">
          <el-form-item label="商户:">
            <el-select v-model="searchInfo.merchant_id" placeholder="请选择商户" filterable clearable allow-create
              style="width: 200px;">
              <el-option v-for="option in optionsMerchant" :key="option.id"
                :label="option.no + '-' + option.merchant_alias" :value="option.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商户全名:">
            <el-select v-model="searchInfo.merchant_id" placeholder="请选择商户" filterable clearable allow-create
              style="width: 200px;">
              <el-option v-for="option in optionsMerchant" :key="option.id"
                :label="option.no + '-' + option.merchant_name" :value="option.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商户类型：" class="form-item">
            <el-select v-model="searchInfo.business_type" placeholder="请选择门店" clearable>
              <el-option v-for="item in merchantsType" :key="item.id" :label="item.label" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="代理商：" class="form-item">
            <el-select v-model="searchInfo.agent_id" placeholder="请选择代理商" clearable filterable>
              <el-option v-for="item in theAgentOption" :key="item.id" :label="item.company" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="联系人：" class="form-item">
            <el-input v-model="searchInfo.contact_person_name" clearable placeholder="请输入联系人" />
          </el-form-item>
          <el-form-item label="联系电话：" class="form-item">
            <el-input v-model="searchInfo.contact_person_phone" clearable placeholder="请输入联系人电话" />
          </el-form-item>
          <el-form-item label="创建时间：">
            <el-date-picker v-model="searchInfo.created_at" value-format="YYYY-MM-DD" type="daterange" align="right"
              unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <div style="display: flex;justify-content: center;">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button @click="handleReset">重置</el-button>
          </div>
        </el-form>
      </div>
    </el-card>

    <div class="more-control">
      <el-button type="primary" @click="handleShift">商户转移</el-button>
    </div>
    <div class="table-content">
      <el-table :data="tableData" style="width: 100%" v-loading="isloading" @selection-change="handleSelectionChange"
        :row-style="isRed">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="no" label="商户号" min-width="100" />
        <el-table-column prop="merchant_name" label="商户名称" show-overflow-tooltip min-width="260">
          <template #default="scope">
            <div @click="handleCopy(scope.row.merchant_name)" style="display: flex; gap: 2px">
              <el-button plain :icon="CopyDocument" size="small" circle></el-button>
              <div>
                {{ scope.row.merchant_name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="merchant_alias" label="商户简称" show-overflow-tooltip min-width="240">
          <template #default="scope">
            <div @click="handleCopy(scope.row.merchant_alias)" style="display: flex; gap: 2px">
              <el-button plain :icon="CopyDocument" size="small" circle></el-button>
              <div>
                {{ scope.row.merchant_alias }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="merchant_name" label="联系人信息" show-overflow-tooltip min-width="180">
          <template #default="scope">
            <span>{{ scope.row.contact_person_name }} -
              {{ scope.row.contact_person_phone }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="business_type" label="商户类型" show-overflow-tooltip min-width="120">
          <template #default="scope">
            <span v-if="scope.row.business_type == 0">企业</span>
            <span v-else-if="scope.row.business_type == 1">个体工商户</span>
            <span v-if="scope.row.business_type == 2">小微商户</span>
            <span v-if="scope.row.business_type == 3">企业商户</span>
          </template>
        </el-table-column>
        <el-table-column prop="salesman.name" label="业务员" min-width="180" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.salesman.name }} -
              {{ scope.row.salesman.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="agent.first_agent_name" label="一级代理商" show-overflow-tooltip min-width="120" />
        <el-table-column prop="agent.second_agent_name" label="二级代理商" show-overflow-tooltip min-width="120" />
        <el-table-column prop="agent.three_agent_name" label="三级代理商" show-overflow-tooltip min-width="120" />
        <el-table-column prop="created_at" label="创建时间" show-overflow-tooltip min-width="120">
          <template #default="scope">
            <span>{{ dayjs(scope.row.created_at).format("YYYY-MM-DD") }} </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="240" fixed="right">
          <template #default="scope">
            <div class="select-btn">
              <el-button type="primary" @click="handleMore(scope.row)" :icon="Search" circle size="small"></el-button>
              <el-button type="success" @click="handleChannel(scope.row)" :icon="Switch" circle size="small">
              </el-button>
              <el-button type="warning" @click="handleStore(scope.row)" :icon="Shop" circle size="small">
              </el-button>
              <el-dropdown style="margin-left: 5px">
                <el-button :icon="Operation" size="small"> 更多</el-button>
                <template #dropdown>
                  <el-dropdown-item @click="handleChannel(scope.row)">商户通道</el-dropdown-item>
                  <el-dropdown-item @click="handleStore(scope.row)">门店管理</el-dropdown-item>
                  <el-dropdown-item @click="handleCashier(scope.row)">收银员管理</el-dropdown-item>
                  <el-dropdown-item @click="handleDevice(scope.row)">设备管理</el-dropdown-item>
                  <el-dropdown-item @click="handleUpdateSmsConfig(scope.row)">短信配置</el-dropdown-item>
                  <el-dropdown-item @click="handleRecharge(scope.row)">服务费率</el-dropdown-item>
                  <el-dropdown-item @click="handleReSetPassword(scope.row)">更改密码</el-dropdown-item>
                  <el-dropdown-item @click="handleDel(scope.row)">删除商户</el-dropdown-item>
                </template>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="空数据" :image-size="200" />
        </template>
      </el-table>
      <div class="pagination">
        <el-pagination background layout="total,prev, pager, next, jumper, slot" :total="pageinfo.total"
          @current-change="pageChange" :page-size="pageinfo.perPage" :current-page="pageinfo.currentPage"
          :hide-on-single-page="false">
        </el-pagination>
      </div>
    </div>
  </div>

  <detail ref="merchant_detail"></detail>
  <cashier ref="merchant_cashier"></cashier>
  <paychannel ref="merchant_paychannel"></paychannel>
  <stores ref="merchant_store"></stores>
  <devices ref="merchant_device"></devices>
  <serviceCharge ref="merchant_recharge"></serviceCharge>
  <shift ref="merchant_shift" @refresh="queryAllMerchant"></shift>
  <resetPassword :open="dialogVisible" @update:open="handleUpdateOpen" :showStoreId="true"
    :merchantDetail="merchantMes">
  </resetPassword>
  <SmsConfigDialog :open="smsDialogVisible" @update:open="handleUpdateSmsOpen" :merchantDetail="merchantMes"
    :reSearch="handleSearch" />
</template>

<style lang="less" scoped>
/* 当鼠标悬停在表格的行上时改变背景色 */
.el-table {
  --el-table-row-hover-bg-color: #cde8f8;
}

.list-container {
  .top-search {

    @media (min-width: 0px)and (max-width: 1440px) {
      .form-item {
        width: 300px;
      }
    }

    @media (min-width: 960px)and (max-width: 1440px) {
      .form-item {
        width: 245px;
      }
    }

    @media (min-width: 1440px)and (max-width: 1920px) {
      .form-item {
        width: 300px;
      }
    }

    @media (min-width: 1920px) {
      .form-item {
        width: 300px;
      }
    }

    .search-btn {
      text-align: center;
    }

    .search-btn .el-button {
      margin: 0 5px;
    }
  }

  .more-control {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .table-content {
    margin: 20px 0;

    .pagination {
      margin-top: 20px;
    }

    .select-btn {
      display: flex;
      align-content: center;
      justify-content: space-evenly;

      .el-dropdown-link {
        color: rgb(64, 158, 255);
      }
    }
  }

  /* src/assets/styles/global.css */
  /* 滚动条整体部分 */
  ::v-deep .el-scrollbar__bar {
    opacity: 1;
    /* 使滚动条不透明 */
  }

  /* 横向滚动条的滑块 */
  ::v-deep .el-scrollbar__bar.is-horizontal .el-scrollbar__thumb {
    border-radius: 1px;
    /* 滑块的圆角 */
    background-color: #818181;
    /* 滑块的背景颜色 */
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
    /* 滑块的阴影 */
  }

  /* 纵向滚动条的滑块 */
  ::v-deep .el-scrollbar__bar.is-vertical .el-scrollbar__thumb {
    border-radius: 1px;
    background-color: #818181;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  }

  /* 滚动条的轨道部分 */
  ::v-deep .el-scrollbar__wrap {
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      /* 轨道的背景颜色 */
    }
  }

  /* 修改滑块宽度 */
  ::v-deep .el-scrollbar__bar.is-horizontal {
    height: 12px;
  }

  ::v-deep .el-scrollbar__bar.is-vertical {
    width: 12px;
  }
}
</style>
